$(document).ready(function () {

  // ---------- Navigation hamburger toggle ----------
  $('.ecnav').on('click', function () {
    $('.animated-icon2').toggleClass('open');
  });

  // ---------- Consulting/Marketing rollover highlights ----------
  $('.callout-list').hover(function () {
    $(this).toggleClass("lighten");
  });
});

// ---------- Animate On Scroll ----------
// https://github.com/michalsnik/aos
AOS.init();

// ---------- Bubbles ----------
var bubble_items = document.getElementsByClassName('bubble-item');
var total_items = bubble_items.length;
var seen = [];

while (seen.length < total_items) {
  random = Math.floor(Math.random() * total_items);
  if (!seen.includes(random)) {
    seen.push(random);
  }
}

seen.forEach(displayIt);

function displayIt(item, index) {
  var minimumStart = 2000;
  var randomWait = (Math.floor(Math.random() * 10) + 1) * 1000;
  var randomWait2 = (Math.floor(Math.random() * 5) + 1) * 150;
  var t = Math.floor(Math.random() * 2);

  setTimeout(function () {
    bubble_items[item].classList.add('bubble-item-float');
    if (t) {
      bubble_items[item].classList.add('float-blue');
    }
  }, randomWait + randomWait2 + minimumStart);
}

// ---------- Scroll to top button ----------
window.onscroll = function() { scrollFunction(); };
function scrollFunction() {
  if (document.body.scrollTop > 40 || document.documentElement.scrollTop > 40) {
     $( "#upBtn" ).css( "opacity", "1.0" ).css("cursor","pointer");
  } else {
    $( "#upBtn" ).css( "opacity", "0" ).css("cursor","default");
  }
}
function topFunction() {
  $("html, body").animate({ scrollTop: "0px" });
} 
